import React from "react";
import classes from "./FooterCopyright.module.css";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../../assets/css/fa.css";

const footerCopyright = () => {
  return (
    <div className={classes.footer__copyright}>
      <div className={classes.bottom}>
        &copy; {new Date().getFullYear()}, Afeedh Shaji{" "}
        <a
          className={["socialIcon", "leftPadding"].join(" ")}
          href="https://gitlab.com/afeedh/web"
        >
          <i className="fa fa-gitlab"></i>
        </a>
      </div>
    </div>
  );
};

export default footerCopyright;
